import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Тату студія «Старт»</title>
        <meta name={"description"} content={"Твоє мистецтво, твоя історія"} />
        <meta property={"og:title"} content={"Тату студія «Старт»"} />
        <meta
          property={"og:description"}
          content={"Твоє мистецтво, твоя історія"}
        />
        <meta
          property={"og:image"}
          content={"https://ua.lixorevstart.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://ua.lixorevstart.com/img/tatoo.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://ua.lixorevstart.com/img/tatoo.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Description-9"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          align-items="flex-start"
          lg-align-items="center"
          flex-direction="column"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          justify-content="flex-start"
          display="flex"
          width="50%"
        >
          <Box>
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline1"
              lg-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Розкрий свій потенціал
            </Text>
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--grey"
            font="--lead"
            lg-text-align="center"
          >
            Тату студія «Старт» - простору, де творчість зустрічається з
            майстерністю в мистецтві татуювання. Наша студія - це притулок для
            тих, хто цінує артистизм та оригінальність у своєму мистецтві на
            тілі. Тут кожне татуювання - це особиста історія, написана з
            точністю та пристрастю.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-y="hidden"
            padding="0px 0px 100% 0px"
            overflow-x="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              object-fit="cover"
              display="block"
              width="100%"
              right={0}
              src="https://ua.lixorevstart.com/img/3.jpg"
              position="absolute"
              top="auto"
              left={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Description-8"
        background="--color-primary"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
        >
          <Box
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            width="100%"
          >
            <Image
              position="absolute"
              display="block"
              top="auto"
              left={0}
              right={0}
              bottom="0px"
              min-height="100%"
              src="https://ua.lixorevstart.com/img/4.jpg"
              object-fit="cover"
              width="100%"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="50%"
          lg-order="1"
          justify-content="flex-start"
          align-items="flex-start"
          padding="24px 24px 24px 24px"
        >
          <Box>
            <Text
              font="--base"
              lg-text-align="center"
              text-transform="uppercase"
              margin="0px 0px 8px 0px"
              color="--light"
              letter-spacing="2px"
            >
              Поринь у свою уяву
            </Text>
            <Text
              margin="0px 0px 36px 0px"
              color="--light"
              font="--headline2"
              lg-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Наші художники
            </Text>
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--light"
            font="--lead"
            lg-text-align="center"
          >
            Тату студія «Старт» народилася з бажання поєднати традиційне
            тату-мистецтво з сучасними стилями в доброзичливому, професійному
            середовищі. Наша студія перетворилася на простір, куди люди з усіх
            верств суспільства можуть прийти, щоб виразити себе через унікальні
            татуювання, створені на замовлення. Ми прагнемо перетворити ваше
            бачення на красиві татуювання в реальність.
            <br />
            <br />
            Серцем студії є наші майстри - кожен з них відібраний за свою
            майстерність, креативність та відданість мистецтву татуювання. Це не
            просто татуювальники, це майстри, які розуміють важливість
            особистого підходу в кожному дизайні, гарантуючи, що ваше татуювання
            буде не тільки витвором мистецтва, але й частиною вашої життєвої
            історії.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h2"
          font="--headline2"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Чому саме ми?
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          Інноваційний дизайн: Від мінімалістичних символів до складних рукавів
          - наше портфоліо свідчить про нашу універсальність.
          <br />
          <br />
          Персоналізований досвід: Ми слухаємо, радимо і створюємо - ваше
          задоволення - це наше мистецтво.
          <br />
          <br />
          Високі стандарти: Ми підтримуємо максимальну чистоту та використовуємо
          найсучасніші фарби та обладнання.
          <br />
          <br />
          Розслаблена атмосфера: Наша студія створена для того, щоб стати вашим
          особистим усамітненням, поки ви малюєте чорнилом.
        </Text>
        <Button
          font="--lead"
          margin="20px"
          type="link"
          text-decoration-line="initial"
          href="/contact"
        >
          Створіть свою легенду з нами
        </Button>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
